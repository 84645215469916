// 会员
<template>
  <div class="about">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="积分套餐" name="first">
        <div class="add-goods">
          <el-button type="primary" @click="addGoods">添加商品</el-button>
        </div>
        <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="displayOrder" label="排序" width="120" align="center"></el-table-column>
          <el-table-column prop="productName" label="套餐名称" align="center"></el-table-column>
          <el-table-column prop="remark" label="套餐内容" align="center"></el-table-column>
          <el-table-column prop="price" label="实付价格" align="center"></el-table-column>
          <el-table-column prop="rawPrice" label="原价" align="center"></el-table-column>
          <el-table-column prop="point" label="积分数" align="center"></el-table-column>

          <el-table-column prop="id" label="商品图片" align="center">
            <template #default="scope">
              <el-image
                class="pre"
                :src="scope.row.bannerDomain  +scope.row.banner "
                :preview-src-list="[scope.row.bannerDomain  +scope.row.banner]"
              ></el-image>
            </template>
          </el-table-column>

          <el-table-column prop="recommend" label="是否推荐" align="center">
            <template #default="scope">
              <span>{{ scope.row.recommend ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="360" align="center">
            <template #default="scope">
              <el-button
                type="text"
                icon="el-icon-edit"
                @click="handleEdit(scope.$index, scope.row)"
              >修改</el-button>
              <el-button
                type="text"
                icon="el-icon-circle-close"
                v-if="scope.row.enable"
                class="red"
                @click="changeStatus(scope.row)"
              >禁用</el-button>
              <el-button
                type="text"
                icon="el-icon-circle-check"
                v-else
                @click="changeStatus( scope.row)"
              >启用</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="VIP套餐" name="second">
        <div>
          <el-form :inline="true">
            <el-form-item label="客户端类型:" required>
              <el-select v-model="clientId" placeholder="请选择">
                <el-option
                  v-for="(item,i) in channelList "
                  :key="i"
                  :label="item.description"
                  :value="item.clientId"
                ></el-option>
              </el-select>
            </el-form-item>
              <el-form-item required>
                <el-button
                  type="primary ml10"
                  style="marginLeft: 10px"
                  icon="el-icon-search"
                  @click="handleClick({index:1})"
                >搜索</el-button>
                <el-button type="primary" @click="addMember ">添加商品</el-button>
              </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="memberList"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="displayOrder" label="排序" width="40" align="center">
            <template #default="scope">
              <span>{{ scope.row.displayOrder }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="memberType" width="120" label="会员类型" align="center">
            <template #default="scope">
              <el-tag  v-if="scope.row.memberType== 1">挽留会员</el-tag>
              <el-tag type="info" v-else>普通会员</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="套餐名称" align="center"></el-table-column>
          <el-table-column prop="clientId" label="客户端类型" align="center">
            <template #default="scope">{{clientIdFilter(scope.row.clientId)}}</template>
          </el-table-column>
          <el-table-column prop="remark" label="套餐内容" align="center"></el-table-column>
          <el-table-column prop="price" label="实付价格" align="center"></el-table-column>
          <el-table-column prop="rawPrice" label="原价" align="center"></el-table-column>

          <el-table-column prop="orderTypeRemark" label="天数" align="center"></el-table-column>

          <el-table-column prop="dailyTime" label="每天免费获得次数" align="center"></el-table-column>

          <el-table-column prop="limitType" width="200" label="分类" align="center">
            <template #default="scope">
              <div class="flex">
                <div>
                  <!-- 0不限；1行业；2职业；3职位 -->
                  <span v-if="scope.row.limitType== 0">不限</span>
                  <span v-if="scope.row.limitType== 1">行业-</span>
                  <span v-if="scope.row.limitType== 2">职业-</span>
                  <span v-if="scope.row.limitType== 3">职位-</span>
                  <span v-for="(item,i) in scope.row.sysProductExtIdName " :key="i">{{item}}</span>
                </div>
                <div>
                  <el-button type="primary" class="limit" @click="openLimit(scope.row)">选择</el-button>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="id" label="商品图片" align="center">
            <template #default="scope">
              <el-image
                class="pre"
                :src="scope.row.bannerDomain  +scope.row.banner "
                :preview-src-list="[scope.row.bannerDomain  +scope.row.banner ]"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="recommend" label="是否推荐" align="center">
            <template #default="scope">
              <span>{{ scope.row.recommend ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="360" align="center">
            <template #default="scope">
              <el-button
                type="text"
                icon="el-icon-edit"
                memberDialog
                @click="memberEdit(scope.$index, scope.row)"
              >修改</el-button>
              <el-button
                type="text"
                icon="el-icon-circle-close"
                v-if="scope.row.enable"
                class="red"
                @click="changeMemberStatus(scope.row)"
              >禁用</el-button>
              <el-button
                type="text"
                icon="el-icon-circle-check"
                v-else
                @click="changeMemberStatus( scope.row)"
              >启用</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <!-- 弹窗 -->
      <el-dialog v-model="showDialog" class="jf-main" :title="jfTitle" width="40%" center>
        <el-form ref="formData" label-width="120px">
          <el-form-item label="排序:" required>
            <el-input
              v-model.trim="row.displayOrder"
              placeholder="为空无效，必填"
              type="Number"
              maxlength="20"
            ></el-input>
          </el-form-item>

          <el-form-item label="套餐名称:" required>
            <el-input v-model.trim="row.productName" placeholder="中英文限20字，必填" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="套餐内容:" required>
            <el-input v-model.trim="row.remark" placeholder="中英文限50字，必填" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="实付价格:" required>
            <el-input
              v-model.trim="row.price"
              @keyup="checkNumTwo"
              max="9999"
              placeholder="0.01~9999，必填"
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item label="原价:" >
            <el-input
              v-model.trim="row.rawPrice"
              max="9999"
              @keyup="checkNumTwo"
              placeholder=""
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item label="积分数:" required>
            <el-input
              v-model.trim="row.point"
              @keyup="checkNumOne"
              placeholder="1-9999,必填"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品图:" required>
            <el-upload
              class="up-btn"
              :http-request="uploadCover"
              action="String"
              :beforeUpload="beforeRemove"
              multiple
              :show-file-list="false"
            >
              <el-button type="primary">点击上传</el-button>
            </el-upload>
            <div class="person-img">
              <el-image
                class="pre"
                :src="row.bannerDomain+row.banner"
                :preview-src-list="[row.bannerDomain+ row.banner]"
              ></el-image>
            </div>
          </el-form-item>
          <el-form-item label="是否推荐:" required>
            <el-select v-model="row.recommend" placeholder="请选择">
              <el-option label="是" :value=" Boolean (true)"></el-option>
              <el-option label="否" :value="Boolean (false)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用:" required>
            <el-select v-model="row.enable" placeholder="请选择">
              <el-option label="是" :value=" Boolean (true)"></el-option>
              <el-option label="否" :value="Boolean (false)"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="saveDialogEvent">保存</el-button>
            <el-button type="primary" @click="showDialog = false">取消</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 会员弹窗 -->
      <el-dialog v-model="memberDialog" class="jf-main" :title="memberTitle" width="40%" center>
        <el-form ref="formData" label-width="170px">
          <el-form-item label="会员类型:" required>
            <el-select v-model="memberItem.memberType" placeholder="请选择">
              <el-option label="普通会员" :value=" Number (0)"></el-option>
              <el-option label="挽留会员" :value="Number (1)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序:" required>
            <el-input
              v-model.trim="memberItem.displayOrder"
              type="Number"
              placeholder="为空无效，必填"
              maxlength="20"
            ></el-input>
          </el-form-item>

          <el-form-item label="套餐名称:" required>
            <el-input v-model.trim="memberItem.productName" placeholder="中英文限20字，必填" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="角标内容:" required>
            <el-input v-model.trim="memberItem.remark" placeholder="中英文限50字，必填" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="实付价格:" required>
            <el-input
              v-model.trim="memberItem.price"
              @keyup="checkNumTwo"
              placeholder="0.01~9999，必填"
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item label="原价:">
            <el-input
              v-model.trim="memberItem.rawPrice"
              @keyup="checkNumTwo"
              placeholder=""
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item label="赠送张数配置">
            <el-input
              v-model.trim="memberItem.redeemTutorial"
              type="textarea"
              :rows="5"
              placeholder='例如：[{"name":"koutu","value":20},{"name":"zjz","value":30}]'
            ></el-input>
          </el-form-item>
          <el-form-item label="赠送张数配置模板">
            <p style="user-select:none;">[{"name":"koutu","value":"20"},{"name":"tool","value":"30"}]  <el-button type="primary" @click="copyFn">复制</el-button></p>
            <p>name后面填功能类型，比如AI抠图填抠图,AI工具填tool,value后面填赠送的张数,符号都是小写</p>
            <p>校验填写格式是不是正确   <el-link type="primary" href='https://www.sojson.com/simple_json.html' target='_blank'>https://www.sojson.com/simple_json.html</el-link></p>
          </el-form-item>
          
          <el-form-item label="权益内容(弹窗背景图）:" required>
            <el-upload
              class="up-btn"
              :http-request="uploadCover"
              action="String"
              :beforeUpload="beforeRemove"
              multiple
              :show-file-list="false"
            >
              <el-button type="primary">点击上传</el-button>
            </el-upload>
            <div class="person-img">
              <el-image
                class="pre"
                :src="memberItem.bannerDomain+memberItem.banner"
                :preview-src-list="[memberItem.bannerDomain+ memberItem.banner]"
              ></el-image>
            </div>
          </el-form-item>
          <el-form-item label="选择天数:" required>
            <el-select v-model="memberItem.orderTypeId" placeholder="请选择">
              <el-option
                v-for="(item,i) in days"
                :key="i"
                :label="item.remark + '每天'+ item.dailyTime"
                :value="Number(item.orderTypeId)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否推荐:" required>
            <el-select v-model="memberItem.recommend" placeholder="请选择">
              <el-option label="是" :value=" Boolean (true)"></el-option>
              <el-option label="否" :value="Boolean (false)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户端类型:" required>
            <el-select v-model="memberItem.clientId" placeholder="请选择">
              <el-option
                v-for="(item,i) in channelList "
                :key="i"
                :label="item.description"
                :value="item.clientId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用:" required>
            <el-select v-model="memberItem.enable" placeholder="请选择">
              <el-option label="是" :value=" Boolean (true)"></el-option>
              <el-option label="否" :value="Boolean (false)"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="saveMemberDialog">保存</el-button>
            <el-button type="primary" @click="memberDialog = false">取消</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 分类弹窗 -->
      <el-dialog v-model="limitDialog" class="limit-main" title="选择分类" width="40%" center>
        <div class="limit-top">
          <el-select v-model="memberItem.limitType" placeholder="请选择">
            <!-- 0不限；1行业；2职业；3职位 -->
            <el-option label="不限" :value=" Number(0)"></el-option>
            <el-option label="行业" :value=" Number(1)"></el-option>
            <el-option label="职业" :value=" Number(2)"></el-option>
            <el-option label="职位" :value=" Number(3)"></el-option>
          </el-select>
          <el-button type="primary" class="limit-btn" @click="searchLabel">搜索</el-button>
        </div>
        <ul class="label-box">
          <li
            class="label"
            v-for="(item,i) in labelList "
            @click="addTag(item)"
            :key="i"
          >ID:{{item.id}}--名称: {{item.name}}</li>
        </ul>
        <div class="limit-tag">
          已经选好:
          <span v-if="memberItem.limitType == 0">当前为不限分类</span>
          <div v-else class="tag-list">
            <el-tag
              v-for="(tag,i) in tags"
              @close="closeTag(i)"
              class="tag"
              :key="tag.name"
              closable
            >{{tag.name}}</el-tag>
          </div>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="limitDialog = false">取消</el-button>
            <el-button type="primary" @click="saveLimitDialog">选好了</el-button>
          </span>
        </template>
      </el-dialog>
    </el-tabs>
  </div>
</template>
<script>
import {
  getJfList,
  getJfChange,
  getUploadMh,
  getJfStatus,
  getMemberList,
  getMemberStatus,
  getMemberChange,
  getDayTimer,
  getAllIndustry,
  getAllOcc,
  getAllPosition,
  clientConfig
} from "../../api/index";

export default {
  data() {
    return {
      clientId:'',
      activeName: "second",
      tableData: [{ name: `1`, id: 1 }],
      showDialog: false,
      memberDialog: false,
      row: {
        recommend: false
      },
      memberList: [],
      memberItem: {},
      jfTitle: "添加积分套餐",
      memberTitle: "添加VIP套餐",
      days: [],
      limitDialog: false, // 分类
      tags: [],
      labelList: [], // 分类
      channelList: "" //渠道列表
    };
  },
  async mounted() {
    await this.queryClientConfig();
    this.handleClick(1)
  },

  methods: {
    copyFn(){
      var cInput = document.createElement('input')
            cInput.value = '[{"name":"koutu","value":20},{"name":"tool","value":30}]'
            document.body.appendChild(cInput)
            cInput.select() // 选取文本框内容

            // 执行浏览器复制命令
            // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
            // Input要在正常的编辑状态下原生复制方法才会生效

            document.execCommand('copy')

            this.$message({
                type: 'success',
                message: '复制成功'
            })
            // 复制成功后再将构造的标签 移除
            document.body.removeChild(cInput)
    },
    clientIdFilter(val) {
      let str = "";
      this.channelList.forEach(element => {
        if (element.clientId == val) {
          str = element.description;
        }
      });
      return str + " " + val;
    },
    queryClientConfig() {
      clientConfig().then(res => {
        if (res.code == 0) {
          this.channelList = res.data;
        }
        console.log("res", res);
      });
    },
    handleClick(tab) {
      this.tabIndex = tab.index
      if (tab.index == 0) {
        this.getList();
      } else {
        this.getMemberData();
        this.getDays();
      }
    },
    // 获取积分列表
    getList() {
      let params = {
        pageIndex: 1,
        pageSize: 100
      };
      getJfList(params).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
        }
      });
    },
    //
    handleEdit(index, row) {
      this.jfTitle = "修改积分套餐";
      console.log(index);
      this.row = row;
      this.showDialog = true;
    },
    addGoods() {
      this.jfTitle = " 新增积分套餐";
      if (this.tableData.length >= 20) {
        this.$message.warning("最多20个商品");
        return false;
      }
      this.row = {
        productId: "",
        productName: "", //套餐名称
        remark: "", //套餐内容
        price: "", //实付价格
        rawPrice: "", //原价
        memPrice: "", //会员价
        banner: "", //banner图
        bannerDomain: "", //banner域名
        recommend: false, //是否推荐
        enable: true, //是否启用
        displayOrder: "", //排序,
        point: "" //积分数
      };
      this.showDialog = true;
    },
    checkNumTwo(e) {
      // 通过正则过滤小数点后两位
      console.log(e);
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
    },
    checkNumOne(e) {
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,0})/g)[0] || null;
      console.log(e.target.value);
    },
    // 获取天数
    getDays() {
      getDayTimer().then(res => {
        if (res.code == 0) {
          this.days = res.data;
        }
      });
    },
    // 积分弹窗保存
    saveDialogEvent() {
      if (this.row.displayOrder.length == 0 || this.row.displayOrder == 0) {
        this.$message.warning("请输入排序，且不为0");
        return false;
      } else if (this.row.productName.length <= 0) {
        this.$message.warning("请输入套餐名称");
        return false;
      }

      if (
        this.row.price.length <= 0 ||
        (this.row.price < 0.01 && this.row.price > 9999) ||
        this.row.price == 0
      ) {
        this.$message.warning("请输入实付价格，范围0.01~9999");
        return false;
      }

      function toDecimal(x) {
        var f = parseFloat(x);
        if (isNaN(f)) {
          return;
        }
        f = Math.round(x * 100) / 100;
        return f;
      }
      if (this.row.price.toString().indexOf(".") > -1) {
        this.row.price = toDecimal(this.row.price);
      }
      if (
        this.row.rawPrice.length <= 0 ||
        (this.row.rawPrice < 0.01 && this.row.rawPrice > 9999) ||
        this.row.rawPrice == 0
      ) {
        this.$message.warning("请输入原价，范围0.01~9999");
        return false;
      }
      if (this.row.rawPrice.toString().indexOf(".") > -1) {
        this.row.rawPrice = toDecimal(this.row.rawPrice);
      }
      if (Number(this.row.rawPrice) < Number(this.row.price)) {
        this.$message.warning("原价必须大于实付价格");
        return false;
      }

      if (
        this.row.point.length <= 0 ||
        this.row.point <= 0 ||
        this.row.point > 9999
      ) {
        this.$message.warning("请输入积分数，1-9999范围内");
        return false;
      } else if (this.row.banner.length <= 0) {
        this.$message.warning("请上传商品图片");
        return false;
      }

      getJfChange(this.row).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.showDialog = false;
          this.getList();
          this.$notify.success({
            title: "提示",
            message: "数据修改成功"
          });
        } else {
          this.$notify.warning({
            title: "提示",
            message: res.message
          });
        }
      });
    },
    strIsJSON(str) {
      // 判断是否为字符串
      if (typeof str == "string") {
        try {
          // 解析字符串
          var obj = JSON.parse(str);
          // 判断类型是否正确，正确就是json
          if (typeof obj == "object") {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          // 解析出错肯定不是字符串
          return false;
        }
      }
    },
    // vip会员保存
    saveMemberDialog() {
      let params = this.memberItem;
      delete params.beginTime;
      delete params.endTime;
      delete params.createTime;
      delete params.updateTime;
      // 天数和每日次数赋值
      let dayList = this.days.filter(item => {
        return this.memberItem.orderTypeId == item.orderTypeId;
      });
      console.log(dayList);
      if (dayList.length > 0) {
        this.memberItem.orderTypeRemark = dayList[0].remark;
        this.memberItem.dailyTime = dayList[0].dailyTime;
      }
      // 校验开始

      if (
        this.memberItem.displayOrder.length == 0 ||
        this.memberItem.displayOrder == 0
      ) {
        this.$message.warning("请输入排序，且不为0");
        return false;
      } else if (this.memberItem.productName.length <= 0) {
        this.$message.warning("请输入套餐名称");
        return false;
      }
      console.log('this.memberItem.redeemTutorial', this.memberItem.redeemTutorial);
      if(this.memberItem.redeemTutorial && !this.strIsJSON(this.memberItem.redeemTutorial)){
        console.log('11111不是json字符串');
        this.$message.error("张数配置格式错误");
        return
      }

      if (
        this.memberItem.price.length <= 0 ||
        (this.memberItem.price < 0.01 && this.memberItem.price > 9999) ||
        this.memberItem.price == 0
      ) {
        this.$message.warning("请输入实付价格，范围0.01~9999");
        return false;
      }
      function toDecimal(x) {
        var f = parseFloat(x);
        if (isNaN(f)) {
          return;
        }
        f = Math.round(x * 100) / 100;
        return f;
      }

      if (this.memberItem.price.toString().indexOf(".") > -1) {
        this.memberItem.price = toDecimal(this.memberItem.price);
      }
      // if (
      //   this.memberItem.rawPrice.length <= 0 ||
      //   (this.memberItem.rawPrice < 0.01 && this.memberItem.rawPrice > 9999) ||
      //   this.memberItem.rawPrice == 0
      // ) {
      //   this.$message.warning("请输入原价，范围0.01~9999");
      //   return false;
      // }
      if (this.memberItem.rawPrice.toString().indexOf(".") > -1) {
        this.memberItem.rawPrice = toDecimal(this.memberItem.rawPrice);
      }

      // if (Number(this.memberItem.rawPrice) < Number(this.memberItem.price)) {
      //   this.$message.warning("原价必须大于实付价格");
      //   return false;
      // }

      if (this.memberItem.banner.length <= 0) {
        this.$message.warning("请上传商品图片");
        return false;
      }

      if (
        this.memberItem.orderTypeId == "" ||
        this.memberItem.orderTypeId == null
      ) {
        this.$message.warning("请选择天数， 不能为空");
        return false;
      }

      // 校验结束
      getMemberChange(params).then(res => {
        if (res.code == 0) {
          this.memberDialog = false;
          this.getMemberData();
          this.$notify.success({
            title: "提示",
            message: "数据编辑成功"
          });
        } else {
          this.$notify.warning({
            title: "提示",
            message: res.message
          });
        }
      });
    },
    // 上传图片校验
    beforeRemove(file, fileList) {
      console.log(fileList);
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "svg" ||
        testmsg === "SVG";
      const isLt50M = file.size / 1024 / 1024 < 1;
      if (!extension) {
        this.$message({
          message: "上传文件只能是jpg或者png或者svg格式!",
          type: "error"
        });
        return false; //必须加上return false; 才能阻止
      }
      console.log(file);
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 1MB!",
          type: "error"
        });
        return false;
      }
      return extension && isLt50M;
    },

    // 上传封面
    uploadCover(params) {
      const file = params.file;
      console.log(params, file, "888");
      var formdata = new FormData();
      formdata.append("file", file);
      getUploadMh(formdata).then(res => {
        console.log(res);
        if (this.memberDialog) {
          this.memberItem.banner = res.data.path;
          this.memberItem.bannerDomain = "https://pptres.qyzhuomian.com";
        } else {
          this.row.banner = res.data.path;
          this.row.bannerDomain = "https://pptres.qyzhuomian.com";
        }
      });
    },
    changeStatus(row) {
      let str = row.enable ? "禁用" : "启用";
      this.$confirm("确定要" + str + "吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          let params = {
            id: row.productId
          };
          getJfStatus(params).then(res => {
            console.log(res);
            this.getList();
            this.$notify.success({
              title: "提示",
              message: "操作成功"
            });
          });
        })
        .catch(() => {});
    },
    changeMemberStatus(row) {
      let str = row.enable ? "禁用" : "启用";
      this.$confirm("确定要" + str + "吗？", "提示", {
        type: "warning"
      })
        .then(() => {
          let params = {
            id: row.productId
          };
          getMemberStatus(params).then(res => {
            console.log(res);
            this.getMemberData();
            this.$notify.success({
              title: "提示",
              message: "操作成功"
            });
          });
        })
        .catch(() => {});
    },

    getMemberData() {
      let params = {
        name: "",
        comboName: "",
        pageIndex: 1,
        pageSize: 100,
        clientId:this.clientId
      };
      getMemberList(params).then(res => {
        console.log(res);
        this.memberList = res.data.data;
      });
    },
    addMember() {
      this.memberItem = {
        productName: "", //套餐名称
        remark: "", //套餐内容
        price: "", //实付价格
        rawPrice: "", //原价
        memPrice: "", //会员价
        orderTypeId: "", //套餐类型id
        orderTypeRemark: "", //天数
        dailyTime: "", //每天次数
        limitType: 0, //限定类
        sysProductExtId: "", //限定类型
        bannerDomain: "", //banner域名D
        banner: "", //banner图
        recommend: false, //是否推荐
        enable: true, //是否启用
        displayOrder: "" //顺序
      };
      this.memberDialog = true;
    },
    memberEdit(index, row) {
      this.memberTitle = "修改VIP套餐";
      let obj = JSON.stringify(row);
      this.memberItem = JSON.parse(obj);
      this.memberDialog = true;
    },
    // 分类弹窗
    openLimit(row) {
      this.searchFlag = false;
      let obj = JSON.stringify(row);
      this.memberItem = JSON.parse(obj);
      this.tags = [];
      if (this.memberItem.limitType == 1) {
        this.getAllHy();
      } else if (this.memberItem.limitType == 2) {
        this.getAllZy();
      } else if (this.memberItem.limitType == 3) {
        this.getAllZw();
      } else if (this.memberItem.limitType == 0) {
        // 不限分类
        this.tags = [];
        this.labelList = [];
      }
      this.limitDialog = true;
    },
    // 保存分类
    saveLimitDialog() {
      if (this.tags.length > 0) {
        let list = [];
        this.tags.map(item => {
          list.push(item.id);
        });
        let str = list.join(",");
        this.memberItem.sysProductExtId = str;
      }
      this.saveMemberDialog();
      this.limitDialog = false;
    },
    // 删除tag
    closeTag(i) {
      console.log(this.tags);
      this.tags.splice(i, 1);
      console.log(this.tags);
    },
    // 搜索
    searchLabel() {
      this.searchFlag = true;
      if (this.memberItem.limitType == 1) {
        this.getAllHy();
      } else if (this.memberItem.limitType == 2) {
        this.getAllZy();
      } else if (this.memberItem.limitType == 3) {
        this.getAllZw();
      } else {
        this.labelList = [];
      }
      this.tags = [];
    },
    // 添加tag
    addTag(item) {
      if (this.memberItem.limitType == 0) {
        this.$message.warning("当前为不限分类，不能添加标签");
        return false;
      }

      let list = this.tags.filter(c => {
        return c.id === item.id;
      });
      if (list.length > 0) {
        this.$notify.warning({
          title: "提示",
          message: "数据已经存在"
        });
        return false;
      } else {
        this.tags.push(item);
        console.log(this.tags);
      }
    },
    // 所有启用行业
    getAllHy() {
      getAllIndustry().then(res => {
        if (res.code == 0) {
          this.labelList = res.data;
          if (!this.searchFlag) {
            let list = this.memberItem.sysProductExtId.split(",");
            this.labelList.map(item => {
              list.map(c => {
                if (c == item.id) {
                  this.tags.push(item);
                }
              });
            });
          }
        }
      });
    },
    // 所有启用职业
    getAllZy() {
      getAllOcc().then(res => {
        if (res.code == 0) {
          this.labelList = res.data;
          if (!this.searchFlag) {
            let list = this.memberItem.sysProductExtId.split(",");
            this.labelList.map(item => {
              list.map(c => {
                if (c == item.id) {
                  this.tags.push(item);
                }
              });
            });
          }
        }
      });
    },
    // 所有启用职位
    getAllZw() {
      getAllPosition().then(res => {
        if (res.code == 0) {
          this.labelList = res.data;
          if (!this.searchFlag) {
            let list = this.memberItem.sysProductExtId.split(",");
            this.labelList.map(item => {
              list.map(c => {
                if (c == item.id) {
                  this.tags.push(item);
                }
              });
            });
          }
        }
      });
    }
  }
};
</script>
<style lang="scss">
.jf-main .up-btn {
  display: inline-block;
  vertical-align: middle;
}
.jf-main .up-btn .btn-text {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #fff;
  line-height: 32px;
}

.up-btn ::v-deep .el-upload {
  width: 98px;
  height: 40px;
  border: 0 !important;
  background-color: #409eff;
  line-height: 20px;
}
.up-btn >>> .el-button--small {
  width: 80px;
  height: 32px;
  border: 0 !important;
  background-color: #409eff;
}
.pre {
  width: 100px;
  height: 100px;
}
.up-btn .el-upload--text {
  border: 0;
  width: 100px;
  height: 40px;
}
.limit {
  margin-left: 10px;
}
.limit-top {
  display: flex;
  justify-content: center;
  .el-input {
    width: 220px;
  }
  .limit-btn {
    margin-left: 10px;
  }
}
.label-box {
  list-style: none;
  margin-top: 20px;
  .label {
    line-height: 40px;
    background: #eee;
    text-align: center;
    margin-bottom: 6px;
    cursor: pointer;
    &:hover {
      background-color: #ccc;
    }
  }
}
.tag {
  margin-right: 4px;
  margin-bottom: 4px;
}
.tag-list {
  display: inline-block;
}
.red {
  color: red;
}
.flex {
  display: flex;
  align-items: center;
}
</style>